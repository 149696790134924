@import "style/utilities/colors";
@import "style/utilities/variables";

.file_upload {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    border: 1px dashed $accentEmphasis;
    border-radius: 4px;
  }

  &__description {
    color: #99a2b2;
    font-size: $fsSmall;
    padding-top: 4px;
    margin-bottom: 30px;
  }

  &__details_container {
    margin-top: 24px;
  }

  &__footer {
    max-width: 200px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 24px;
  }

  &__progress_bar {
    display: flex;
    width: 100%;
    border-top: 1px solid #28303e;
    padding: 9px 0px;
    align-items: center;
    justify-content: space-between;

    &_file_name {
      color: #868e9f;
      margin-right: 60px;
    }
  }

  &__progress_bar_close {
    background: none;
    border: none;
    display: flex;
  }

  &__error {
    margin-top: 8px;
  }
}
