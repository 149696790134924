@import "style/utilities/colors";
@import "style/utilities/variables";

.side-nav {
  display: inline-flex;
  flex-direction: column;
  padding: 8px;
  margin-top: 100px;

  &__list_item {
    list-style-type: none;
    text-align: center;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    color: $fgDefault;
    text-decoration: none;
    font-size: 8px;
    margin-bottom: 12px;
    padding: 5px;

    &--active {
      background: $grey950;
      border-radius: 4px;
      border: 1px solid #e028dd
    }

    &--disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}
