@import 'style/utilities/colors';
@import 'style/utilities/variables';

.maturity-radio-button-group {
  font-size: 12px;

  .radio-option {
    background-color: $grey950;
    height: 28px;
    border-radius: 50px;
    padding: 8px 16px 0 16px;
  }

  .radio-option-checked {
    background-color: $accentSubtle;
  }

  input[type='radio'] {
    width: 16px;
    height: 16px;
    border: 1px solid $slate300;
    transform: translateY(-1px);
  }

  input[type='radio']:checked {
    border-color: $slate50;
  }

  input[type='radio']::before {
    width: 8px;
    height: 8px;
    box-shadow: inset 1em 1em $slate50;
  }

  input[type='radio']:focus {
    outline: max(2px, 0.15em) solid $slate50;
    outline-offset: max(2px, 0.15em);
  }
}
