@import "utilities/colors.scss";
@import "mixins.scss";
@import url('react-tooltip/dist/react-tooltip.css');
@import url('rc-slider/assets/index.css');

html {
  line-height: 1.15;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

*,
::before,
::after {
  box-sizing: border-box;
}

/* Hide arrow button in input, Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  background-color: $sectionBackground;
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-corner {
  background-color: $sectionBackground;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: darken($sectionHighlight, 5%);
  &:hover {
    background-color: $sectionHighlight;
  }
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: $scrollBarBackground;
}

//Page Container
.page-container {
  display: grid;
  background: $bgApp;
  grid-template-columns: min-content 10px 1fr 24px;
  grid-template-rows: min-content 1fr;
  grid-template-areas: 
    "gap gap summery gap3"
    "sidebar gap2 page gap3";

  .sidebar {
    grid-area: sidebar;
    min-width: 14px;
  }

  .summery {
    grid-area: summery;
    padding: 12px;
  }

  .filters-actions {
    display: flex;
    justify-content: space-between; // Puts filters on the left, and actions on the right.
  }

  .page {
    grid-area: page;
    height: 100%;
    padding: 12px;
    background: $bgCanvas;
    border-radius: 8px;
    overflow: auto;
  }
}

//seems to be still in use.... remove it when you can
.ReactModalPortal {
  h2 {
    color: white;
  }

  .container {
    min-width: 800px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.39);
  }

  .modal-header {
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    color: rgba(255, 255, 255, 0.28);
    background-color: rgba(0, 0, 0, 0.28);

    h5 {
      font-size: 16px;
    }

    .close {
      span {
        color: white;
      }
    }
  }

  .modal-content {
    @include input-style;

    position: absolute;
    background-color: $deprecatedModalBackground;
    bottom: auto;
    color: white;
    min-height: 600px;
    left: 50%;
    margin-right: -50%;
    right: auto;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 900px;

    .map-table {
      color: white;
      margin-bottom: 0;
      table-layout: fixed;

      table {
        width: 100%;
        border-spacing: 0;
      }

      thead,
      tbody,
      tr,
      th,
      td {
        display: block;
      }

      thead tr {
        /* fallback */
        width: 97%;
        /* minus scroll bar width */
        width: -webkit-calc(100% - 16px);
        width: -moz-calc(100% - 16px);
        width: calc(100% - 16px);
      }

      tr:after {
        /* clearing float */
        content: " ";
        display: block;
        visibility: hidden;
        clear: both;
      }

      tbody {
        height: 360px;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          background-color: $deprecatedModalBackground;
        }
      }

      tbody td,
      thead th {
        width: 49.5%; /* 19% is less than (100% / 5 cols) = 20% */
        float: left;
      }

      td {
        border-top: 1px dotted rgba($color: white, $alpha: 0.3);
      }
    }

    .map-table-4col {
      color: white;
      margin-bottom: 0;
      table-layout: fixed;

      table {
        width: 100%;
        border-spacing: 0;
      }

      thead,
      tbody,
      tr,
      td,
      td {
        display: block;
      }

      thead tr {
        /* fallback */
        width: 97%;
        /* minus scroll bar width */
        width: -webkit-calc(100% - 16px);
        width: -moz-calc(100% - 16px);
        width: calc(100% - 16px);
      }

      tr:after {
        /* clearing float */
        content: " ";
        display: block;
        visibility: hidden;
        clear: both;
      }

      tbody {
        height: 360px;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          background-color: $deprecatedModalBackground;
        }
      }

      tbody td,
      thead th {
        width: 30%;
        float: left;
        border-bottom-style: none;
      }

      td {
        border-top: 1px dotted rgba($color: white, $alpha: 0.3);
      }
    }
  }

  h2 {
    color: white;
    font-size: 24px;
  }
}

// HACK(kentskinner): this is so the DatePicker dropdown appears OVER the floating modal dialog.
div[data-baseweb="popover"] {
  z-index: 1000 !important;
}