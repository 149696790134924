@import "style/utilities/colors";
@import "style/utilities/variables";

.document-library {
  background: $black;
  display: flex;
  width: 100%;
  border-radius: 8px;
  padding: 12px;

  &__container {
    padding: 12px;
    border: 1px solid $grey900;
    border-radius: 8px;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }

  &__header {
    font-size: $fsLead;
  }
}
