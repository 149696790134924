@import 'utilities/colors.scss';

@mixin input-style($backgroundColor: $deprecatedModalBackground) {
  input[type='text'] {
    background-color: lighten($backgroundColor, 3%);
    border-radius: 0;
    border-style: none none solid none;
    border-color: $brandMainPink;
    color: white;

    &:focus {
      box-shadow: none;
    }
  }

  input[type='number'] {
    background-color: lighten($backgroundColor, 3%);
    border-radius: 0;
    border-style: none none solid none;
    border-color: $brandMainPink;
    color: white;
    -moz-appearance: textfield;

    &:focus {
      box-shadow: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type='radio'] {
    background-color: $backgroundColor;
    border-radius: 0;
    border-style: none none solid none;
    border-color: $brandMainPink;
    color: white;

    &:focus {
      box-shadow: none;
    }
  }

  .input-center {
    text-align: center;
  }
}
