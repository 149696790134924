@import "style/utilities/colors";
@import "style/utilities/variables";

.document-category {
  margin-top: 8px;

  &__header {
    display: flex;
    justify-content: space-between;
    background: #ffffff0d;
    padding: 10px 8px;
    align-items: center;
  }

  &__name {
    font-size: 14px;
    color: #e8e9ec;
  }

  &__subheader {
    color: $slate200;
    padding: 8px;
  }
}
