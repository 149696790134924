@import 'style/utilities/colors';
@import 'style/utilities/variables';

/*
 * To style radio buttons, I'm using the approach described at:
 * https://moderncss.dev/pure-css-custom-styled-radio-buttons/
 */

.radio-button-group {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  margin: 15px 0;
  gap: 20px;
  align-items: center;

  .radio-option {
    display: flex;
  }

  label {
    line-height: 1.1;
    color: $fgDefault;
    white-space: nowrap;
  }

  input[type='radio'] {
    appearance: none;
    background-color: transparent;
    margin: 0 10px 0 0;
    font: inherit;
    color: $fgDefault;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translateY(-4px);

    display: grid;
    place-content: center;
  }

  input[type='radio']:checked {
    border-color: $accentDefault;
  }

  input[type='radio']::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $accentDefault;
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  input[type='radio']:focus {
    outline: max(2px, 0.15em) solid $accentDefault;
    outline-offset: max(2px, 0.15em);
  }
}
