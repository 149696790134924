.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

html,
body,
#root {
  overflow: hidden;
}
