@import "style/utilities/colors";
@import "style/utilities/variables";

.c-button {
  border-radius: 4px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &--primary {
    background: $accentDefault;
    border: 2px solid $accentDefault;
    color: $fgOnAccent;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1);
    outline: none;

    &:hover {
      background: $accentMuted;
      border-color: $accentMuted;
      color: $fgDefault;

      & svg {
        fill: $fgDefault;
      }
    }

    &:active {
      background: $accentEmphasis;
      border-color: $accentEmphasis;
    }

    &:focus {
      border-color: $accentEmphasis;
    }

    &:disabled {
      background: $accentDisabled;
      border-color: $accentDisabled;
      color: $fgOnDisabled;

      & svg {
        fill: $fgOnDisabled;
      }
    }
  }

  &--secondary {
    background: transparent;
    color: $accentDefault;
    border: 1px solid $accentDefault;

    & svg {
      fill: $accentDefault;
    }

    &:hover {
      border: 1px solid $accentMuted;
      color: $accentMuted;

      & svg {
        fill: $accentMuted;
      }
    }

    &:active {
      border: 1px solid $accentEmphasis;
      color: $accentEmphasis;

      & svg {
        fill: $accentEmphasis;
      }
    }

    &:disabled {
      border: 1px solid $fgMuted;
      background: $fgOnAccent;
      color: $fgMuted;

      & svg {
        fill: $fgMuted;
      }
    }
  }

  &--tertiary {
    background: #333333;
    color: #BDBDBD;
    border: 1px solid #333333;

    & svg {
      fill: #BDBDBD;
      width: 16px;
      height: 16px;
    }

    &:hover {
      border: 1px solid $black;
      color: #e8e9ec;

      & svg {
        color: #e8e9ec;
      }
    }

    &:active {
      border: 1px solid #e8e9ec;
      color: #e8e9ec;

      & svg {
        fill: #e8e9ec;
      }
    }

    &:disabled {
      border: 1px solid $grey200;
      background: $fgOnAccent;
      color: $grey200;

      & svg {
        fill: $grey200;
      }
    }
  }

  &__icon__right {
    flex-direction: row-reverse;

      & .c-button__label {
        margin-left: 0;
        margin-right: 4px;
      }
  }

  &--small {
    font-size: $fsXSmall;
    line-height: 16px;
    padding: 6px 16px;
  }

  &--medium {
    font-size: $fsSmall;
    line-height: 16px;
    padding: 10px 16px;
  }

  &--large {
    font-size: $fsBody;
    line-height: 20px;
    padding: 10px 16px;
  }

  &--extra-large {
    font-size: $fsLead;
    line-height: 24px;
    padding: 14px 16px;
  }

  &__label {
    margin-left: 4px;
  }
}
