@import "style/utilities/colors";
@import "style/utilities/variables";

.dropdown {
  position: relative;
  width: fit-content;

  &__container {
    margin: 0;
    padding: 0;
    background: $grey950;
    border-radius: 6px;
    box-shadow: 0px 8px 16px 0px rgba(13, 13, 13, 0.9);
    overflow: hidden;
    min-width: 100%;
    max-width: fit-content;
    position: absolute;
    z-index: 100;

    &--right {
      right: 0;
    }

    &--left {
      left: 0;
    }
  }

  &__option {
    list-style-type: none;
  }

  &__option_button {
    border: none;
    background: $grey950;
    color: $fgDefault;
    width: 100%;
    padding: 8px 16px;
    text-align: left;
    font-size: $fsLead;

    &:hover {
      background: $accentDefault;
      color: $fgOnAccent;
    }
  }

  &__content {
    margin-right: 32px;
  }

  &__button {
    display: flex;
    background: $grey950;
    border: none;
    padding: 10px 12px;
    color: $fgDefault;
    font-size: $fsXSmall;
    align-items: center;
    border-radius: 4px;
  }

  &__avatar {
    margin-right: 8px;
  }

  &__icon {
    margin-right: 8px;
  }
}
