@import "style/utilities/typography";
@import "style/utilities/colors";
@import "style/utilities/typography";

.sub_header_menu_item {
  align-self: end;

  &__list_item {
    list-style-type: none;
    display: flex;
    align-items: center;
  }

  &__link {
    padding: 10px 24px;
    color: $grey500;
    font: $primaryXSmallBold;
    border-bottom: 2px solid transparent;
    text-decoration: none;

    &--active {
      box-sizing: border-box;
      border-bottom: 2px solid $accentDefault;
      color: $fgDefault;
    }

    &--disabled {
      pointer-events: none;
    }

    &--disabled:hover {
      color: initial;
    }

    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
}
