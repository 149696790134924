@import "style/utilities/colors";
@import "style/utilities/variables";

.avatar {
  width: 30px;
  height: 30px;
  background: $accentMuted;
  color: $fgDefault;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}