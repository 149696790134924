@import "style/utilities/colors";
@import "style/utilities/typography";
@import "style/utilities/variables";


.pill-combo {
  margin-top: 4px;
  width: fit-content;
  background: $grey950;
  border: 1px solid $accentEmphasis;
  border-radius: 6px;
  box-shadow: 0px 8px 16px 0px rgba(13, 13, 13, 0.9);
  padding: 16px 12px;

  &__label {
    color: $fgDefault;
    font-size: $fsXSmall;
  }

  &__option {
    margin-top: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__close {
    background: none;
    border: none;

    &:hover,
    &:active {
      background: #3b4351;
    }
  }

  &__menu_desc {
    margin-top: 12px;
    color: $fgLabelDefault;
    font: $primaryXSmall
  }

  &__no_results {
    margin-top: 16px;
    color: $fgDefault;
    font: $primaryXSmall
  }
}
