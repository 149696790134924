/* Font Sizes */
$fsXSmall: 12px;
$fsCaption: 14px;
$fsSmall: 14px;
$fsBody: 16px;
$fsLead: 18px;
$fsHeadline6: 20px;
$fsHeadline5: 24px;
$fsHeadline4: 28px;
$fsHeadline3: 32px;
$fsHeadline2: 36px;
$fsHeadline1: 40px;

/* Border Radius */


