@import "style/utilities/colors";
@import "style/utilities/variables";

.portfolio-dashboard {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-grow: 1;

  &__welcome_container {
    position: absolute;
    border: 1px solid #3b4351;
    padding: 24px;
    max-width: 400px;
    left: 0;
    top: 3%;
    background: $black;
    border-radius: 8px;
    color: #e8e9ec;
    margin-left: 40px;
    filter: drop-shadow(0px 16px 48px rgba(13, 13, 13, 0.22)) drop-shadow(0px 16px 48px rgba(13, 13, 13, 0.22));

    &:before,
    &:after {
      border-style: solid;
      border-width: 1px 1px 0 0;
      border-color: #3b4351;
      background: $black;
      content: "";
      height: 40px;
      width: 40px;
      left: -20px;
      position: relative;
      top: 43%;
      transform: rotate(-135deg);
      vertical-align: top;
      position: absolute;
    }
  }

  &__header_text,
  &__header_subtext {
    font-family: "PT Sans Narrow";
    font-size: 40px;
    font-weight: bold;
  }

  &__header_subtext {
    color: fgLabelDefault;
  }

  &__desc {
    padding-top: 24px;
    font-size: 20px;
  }

  &__img {
    width: 475px;
    height: 380px;
    opacity: 0.4;
  }
}
